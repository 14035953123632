import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Common/Loader";
import { routes } from "../../constants";
import { login, getCCAllAgents } from "../../services/ApiService";
import Logo from "../../assets/images/logo/main_logo.png";
import Egg from "../../assets/images/logo/egg.jpeg";
import { getIsLoggedIn, DappUI } from "@elrondnetwork/dapp-core";
// import Sound from "../../assets/sounds/welcome.mp3";
import ThankYou from '../../assets/images/common-images/thank_you.png';
import Done from '../../assets/images/common-images/tumuloy.png'
import CheckBoxCheck from '../../assets/images/common-images/checkBoxCheck.png';
import {
  Form,
  FormControl
} from "react-bootstrap";
import {
  checkRxLogin
} from "../../services/ApiService";
import {
  isMobile,
  browserName,
  isAndroid,
  isIOS,
  isChrome,
  isSafari,
} from "react-device-detect";
import "./style.css";
import "./styles.css";
import useStyles from './styles';
import InnerPageHeader from "../../Components/InnerPageHeader";
// import Maiar from "../../assets/images/logo/maiar.png";

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import {
  uploadMedia
} from "../../services/ApiService";

const LoginPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { WalletConnectLoginButton } = DappUI;
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [level, setLevel] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [address, setAddress] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [scIdNo, setScIdNo] = useState("");
  const hiddenFileInput = React.useRef(null);
  const [tabArr, setTabArr] = useState([
    { tabId: 1, tabName: 'Agahan', isSelect: true },
    { tabId: 2, tabName: 'Tanghalian', isSelect: false },
    { tabId: 3, tabName: 'Hapunan', isSelect: false },
  ])
  const [timeId, setTimeId] = useState(1);
  const [agents, setAgents] = useState([]);
  const isLandscape = () => window.matchMedia('(orientation:landscape)').matches,
      [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait'),
      onWindowResize = () => {              
        clearTimeout(window.resizeLag)
        window.resizeLag = setTimeout(() => {
          delete window.resizeLag                       
          setOrientation(isLandscape() ? 'landscape' : 'portrait')
        }, 200)
      }

  useEffect(() => (
    onWindowResize(),
    window.addEventListener('resize', onWindowResize),
    () => window.removeEventListener('resize', onWindowResize)
  ),[])

  useEffect(async () => {
    const response = await getCCAllAgents();
    if (response.data.length > 0) {
      console.log(`agents=${JSON.stringify(response.data)}`)
      setAgents(response.data);
    }
  }, [])

  const isLoggedIn = getIsLoggedIn();
  useEffect(() => {
    if (isLoggedIn) {
      console.log("loggg", isLoggedIn);
      // localStorage.removeItem("persist:dapp-core-store");
      localStorage.setItem("isNewUser", "0");
      const user =
        localStorage.getItem("token") &&
        JSON.parse(localStorage.getItem("token"));
      if (user) {
        // window.location.href = routes.home;
        navigate(routes.home);
      }
    }
  }, [isLoggedIn]);

  // const loginHandler = async (oldId, apiKey, wefftWalletAddress) => {
  const loginHandler = async (oldId, apiKey) => {
    try {
      setLoader(true);
      const response = await login({
        oldId,
        apiKey,
      });
      if (response) {
        setLoader(false);
        // console.log("Login Response => ", response.data);
        localStorage.setItem(
          "token",
          JSON.stringify(response.headers.authorization)
        );
        // localStorage.setItem("wefftWalletAddress", wefftWalletAddress);
        // localStorage.setItem("maiarAddress", wefftWalletAddress);
        localStorage.setItem("footerIndex", 0);
        localStorage.setItem("itemIndex", 0);
        localStorage.setItem("oldId", oldId);
        localStorage.setItem("isNewUser", "1");
        localStorage.setItem("autoJoin", "0");
        navigate(routes.home);
        // window.open(routes.home);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const onDonePress = async (e) => {
    if (code.trim() == "") {
      setErrorMsg('Invalid Code');
    }
    else if (password.trim() == "" || password.length != 6) {
      setErrorMsg('Invalid Password');
    }
    else {
      window.scrollTo(0, 0);
      try {
        const response = await checkRxLogin({code: code, birthdate: password});
        if (response) {
          if (response.data.length > 0) {
            loginHandler(response.data[0].oldId, response.data[0].apiKey)
            return;
          }
          else {
            setErrorMsg('Invalid Code or Password')
          }
        }
      } catch (e) {
        console.log(e);
        setErrorMsg('Invalid Code or Password')
      }
    }
  }

  const handleFile = async (file) => {
    const formData = new FormData();
    if (!file) {
      window.alert(`!File`);
      return;
    }
    formData.append("file", file);

    const response = await uploadMedia(formData);
    // console.log("response", JSON.stringify(response));
    try {
      // const upload = await uploadResetaImage({imageBig: response.data.data[0].imageUrlBigUrl})
      // if (upload) {
      //   // console.log(`upload.response=${JSON.stringify(upload)}`)
      //   await fetchData();
      // }
    } catch (e) {
      console.log(e);
    }
  }

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    const mArray = fileUploaded.name.split(".");
    const fileExtension = mArray[mArray.length - 1];
    const allowedFileTypes = ["jpg", "png", "jpeg"];
    if (!allowedFileTypes.includes(fileExtension)) {
        window.alert(`File type not supported.`);
    }
    else {
      handleFile(fileUploaded);
    }
  };

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const onTumuloyPress = () => {
    setLevel(2);
  }

  const medOptions = [
    { value: 1, label: 'Losartan' },
    { value: 2, label: 'Atorvastatin' },
    { value: 3, label: 'Metformin' },
  ];
  const defaultMedOption = medOptions[0];


  const dosOptions = [
    { value: 1, label: '20mg' },
    { value: 2, label: '50mg' },
    { value: 3, label: '500mg' },
  ];
  const defaultDosOption = dosOptions[0];

  const _onSelect = () => {

  }

  const tabAction = (index) => {
    let arr = tabArr
    arr.map((item, i) =>
        arr[i].isSelect = false
    )
    arr[index].isSelect = true
    setTimeId(index + 1);
    setTabArr(arr);
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        minHeight: "100vh",
        flexDirection: "column",
      }}
    >
      {loader && <Loader />}
      <img
        src={Logo}
        style={{
          width: 200,
          height: 200,
          borderRadius: "50%",
          objectFit: "cover",
          marginTop: 40,
          marginBottom: 30,
        }}
      />
       <p
        style={{
          color: "#dc2828",
          marginTop: 0,
          fontFamily: "CanaroMedium",
          fontSize: 40,
        }}
      >
        {process.env.REACT_APP_IS_MD_CLUB ? 'RxCallCenter' : 'RxPharmacist'}
      </p>
      { Number(process.env.REACT_APP_FAKE_LOGIN) === 1 && <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 30
        }}
      >
        <button
          onClick={() =>
            loginHandler(
              "156f0f92-6f3b-43a0-990b-b596028ef931",
              "vxc7mzv61V1ZmjoWeNAiir",
              "erd1e2rfnsg2dlqsaf5q7qmeweuxkt8rshe2jdnwce87rend4crax0vq0nrzu7"
            )
          }
          style={{
            padding: "0.5rem 0.75rem",
            width: 320,
            color: "#404040",
            marginTop: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 80,
            borderRadius: 25,
            backgroundColor: "white",
            fontSize: '28px',
            border: 'solid 3px #dc2828'
          }}
        >
          <img
            src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1670295940356.png"
            style={{
              width: 48,
              height: 48,
              objectFit: "cover",
              borderRadius: "50%",
              marginRight: 10,
            }}
          />
          Je Santos
          {agents.filter(item => item.id == 131155).length > 0 && <p style={{color: 'green', fontSize: 12, marginLeft: 10}}>active</p>}
        </button> 
        <button
          onClick={() =>
            loginHandler(
              "13eac760-725d-11ea-89b2-0242ac110008",
              "JREFlHu7RB92CawjM5hp5k0uPh9JUptq",
              "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6ygzlszae7sn"
            )
          }
          style={{
            padding: "0.5rem 0.75rem",
            width: 320,
            color: "#404040",
            marginTop: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 80,
            borderRadius: 25,
            backgroundColor: "white",
            fontSize: '28px',
            border: 'solid 3px #dc2828'
          }}
        >
          <img
            src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1669019167346_resized.jpg"
            style={{
              width: 48,
              height: 48,
              objectFit: "cover",
              borderRadius: "50%",
              marginRight: 10,
            }}
          />
          Lance Cruz 
          {agents.filter(item => item.id == 8936).length > 0 && <p style={{color: 'green', fontSize: 12, marginLeft: 10}}>active</p>}
        </button>
      </div> 
      }
      <p style={{ color: "#404040", marginTop: 60 }}>
        {process.env.REACT_APP_VERSION_PREFIX} {process.env.REACT_APP_VERSION}
      </p>
    </div>
  );
};

export default LoginPage;
